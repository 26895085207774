import React  from 'react';


// Shared Components
import Footer from "../components/shared/footer/Footer";
import Header from "../components/shared/header/Header";
import Seo from "../components/seo"

// Tabs
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

// Images
import Terms from '../images/terms-of-service/terms.png'


export default function TermsOfService() {

    return (
        <div>
            <Seo title="Terms of Service and Privacy Policy" description="Learn about Tech Tester's Terms of Service, Privacy Policy, and Cookie Policy." />
            <Header/>
            <div className="mx-auto">
                {/* START HERO SECTION */}
                <div className="bg-myGray-300">
                    <div className={"py-18 px-10 mx-auto max-w-6xl grid grid-cols-1 md:grid-cols-2 gap-10"}>
                        <div className={"my-auto text-left "}>
                            <h1 className={"md:text-4xl text-3xl text-myGray-600 font-bold"}>Terms of Service and Privacy Policy</h1>
                            <h3 className={"text-myGray-600 text-lg pt-10"}>By sharing your email with us, you agree to our Terms of Service and Privacy Policy.<br/>
                                We also use cookies to make our site work and for analytics purposes. See our Cookie Policy below for more details.</h3>
                        </div>
                        <div className={"m-auto"}>
                            <img
                                className=""
                                src={Terms}
                                alt="Temp Item"
                            />
                        </div>
                    </div>
                </div>
                {/* END HERO SECTION */}

                <div className="relative overflow-hidden">
                    <div aria-hidden="true" className="absolute z-10 inset-x-0 top-0 h-8 md:h-24 bg-gradient-to-b from-gray-100"/>
                    <div className="p-4 md:p-8 mx-0 md:mx-auto md:px-24 md:py-24 max-w-6xl min-w-full">
                        <Tabs>
                            <TabList>
                                <Tab><span className="text-xs md:text-sm lg:text-md">Privacy Policy</span></Tab>
                                <Tab><span className="text-xs md:text-sm lg:text-md">Terms of Service</span></Tab>
                                <Tab><span className="text-xs md:text-sm lg:text-md">Cookie Policy</span></Tab>
                            </TabList>

                            <TabPanel className={"mt-8"}>
                                <h1 className="text-2xl mb-4 font-bold">Privacy Policy</h1>
                                <p>Your privacy is important to us. It is Alarm Tech Tester's policy to respect your privacy and comply with any applicable law and regulation regarding any personal information we may collect about you, including across our website, https://alarmtechtester.com, and other sites we own and operate.</p><br/>
                                <p>Personal information is any information about you which can be used to identify you. This includes information about you as a person (such as name, address, and date of birth), your devices, payment details, and even information about how you use a website or online service.</p><br/>
                                <p>In the event our site contains links to third-party sites and services, please be aware that those sites and services have their own privacy policies. After following a link to any third-party content, you should read their posted privacy policy information about how they collect and use personal information. This Privacy Policy does not apply to any of your activities after you leave our site.</p><br/>
                                <p className="font-bold">This policy is effective as of 18 March 2022.</p><p className="font-bold">Last updated: 18 March 2022.</p><br/>
                                <h2 className="font-bold">Information We Collect</h2>
                                <p>Information we collect includes both information you knowingly and actively provide us when using or participating in any of our services and promotions, and any information automatically sent by your devices in the course of accessing our products and services.</p><br/>
                                <h3 className="font-bold">Log Data</h3>
                                <p>When you visit our website, our servers may automatically log the standard data provided by your web browser. It may include your device’s Internet Protocol (IP) address, your browser type and version, the pages you visit, the time and date of your visit, the time spent on each page, and other details about your visit.</p><br/>
                                <p>Additionally, if you encounter certain errors while using the site, we may automatically collect data about the error and the circumstances surrounding its occurrence. This data may include technical details about your device, what you were trying to do when the error happened, and other technical information relating to the problem. You may or may not receive notice of such errors, even in the moment they occur, that they have occurred, or what the nature of the error is. Please be aware that while this information may not be personally identifying by itself, it may be possible to combine it with other data to personally identify individual persons.</p><br/>
                                <h3 className="font-bold">User-Generated Content</h3>
                                <p>We consider “user-generated content” to be materials voluntarily supplied to us by our users for the purpose of publication on our website and/or social media channels. All user-generated content is associated with the account or email address used to submit the materials.</p><br/>
                                <p>Please be aware that any content you submit for the purpose of publication will be public after posting (and subsequent review or vetting process). Once published, it may be accessible to third parties not covered under this privacy policy.</p><br/>
                                <h3 className="font-bold">Collection and Use of Information</h3>
                                <p>We may collect personal information from you when you do any of the following on our website:</p><br/>
                                <ul className="pl-4">
                                    <li className="list-disc">Use a mobile device or web browser to access our content</li>
                                    <li className="list-disc">Contact us via email, social media, or on any similar technologies</li>
                                    <li className="list-disc">When you mention us on social media</li>
                                </ul><br/>
                                <p>Please be aware that we may combine information we collect about you with general information or research data we receive from other trusted sources.</p><br/>
                                <h3 className="font-bold">Security of Your Personal Information</h3>
                                <p>When we collect and process personal information, and while we retain this information, we will protect it within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use, or modification.</p><br/>
                                <p>Although we will do our best to protect the personal information you provide to us, we advise that no method of electronic transmission or storage is 100% secure, and no one can guarantee absolute data security. We will comply with laws applicable to us in respect of any data breach.</p><br/>
                                <p>You are responsible for selecting any password and its overall security strength, ensuring the security of your own information within the bounds of our services.</p><br/>
                                <h3 className="font-bold">How Long We Keep Your Personal Information</h3>
                                <p>We keep your personal information only for as long as we need to. This time period may depend on what we are using your information for, in accordance with this privacy policy. If your personal information is no longer required, we will delete it or make it anonymous by removing all details that identify you.</p><br/>
                                <p>However, if necessary, we may retain your personal information for our compliance with a legal, accounting, or reporting obligation or for archiving purposes in the public interest, scientific, or historical research purposes or statistical purposes.</p><br/>
                                <h3 className="font-bold">Children's Privacy</h3>
                                <p>We do not aim any of our products or services directly at children under the age of 13, and we do not knowingly collect personal information about children under 13.</p><br/>
                                <h3>Your Rights and Controlling Your Personal Information</h3>
                                <p>You always retain the right to withhold personal information from us, with the understanding that your experience of our website may be affected. We will not discriminate against you for exercising any of your rights over your personal information. If you do provide us with personal information you understand that we will collect, hold, use and disclose it in accordance with this privacy policy. You retain the right to request details of any personal information we hold about you.</p><br/>
                                <p>If we receive personal information about you from a third party, we will protect it as set out in this privacy policy. If you are a third party providing personal information about somebody else, you represent and warrant that you have such person’s consent to provide the personal information to us.</p><br/>
                                <p>If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time. We will provide you with the ability to unsubscribe from our email-database or opt out of communications. Please be aware we may need to request specific information from you to help us confirm your identity.</p><br/>
                                <p>If you believe that any information we hold about you is inaccurate, out of date, incomplete, irrelevant, or misleading, please contact us using the details provided in this privacy policy. We will take reasonable steps to correct any information found to be inaccurate, incomplete, misleading, or out of date.</p><br/>
                                <p>If you believe that we have breached a relevant data protection law and wish to make a complaint, please contact us using the details below and provide us with full details of the alleged breach. We will promptly investigate your complaint and respond to you, in writing, setting out the outcome of our investigation and the steps we will take to deal with your complaint. You also have the right to contact a regulatory body or data protection authority in relation to your complaint.</p><br/>
                                <h3 className="font-bold">Use of Cookies</h3>
                                <p>We use “cookies” to collect information about you and your activity across our site. A cookie is a small piece of data that our website stores on your computer, and accesses each time you visit, so we can understand how you use our site. This helps us serve you content based on preferences you have specified.</p><br/>
                                <p>Please refer to our Cookie Policy for more information.</p><br/>
                                <h3 className="font-bold">Limits of Our Policy</h3>
                                <p>Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and policies of those sites, and cannot accept responsibility or liability for their respective privacy practices.</p><br/>
                                <h3 className="font-bold">Changes to This Policy</h3>
                                <p>At our discretion, we may change our privacy policy to reflect updates to our business processes, current acceptable practices, or legislative or regulatory changes. If we decide to change this privacy policy, we will post the changes here at the same link by which you are accessing this privacy policy.</p><br/>
                                <p>If required by law, we will get your permission or give you the opportunity to opt in to or opt out of, as applicable, any new uses of your personal information.</p><br/>
                                <h3 className="font-bold">Contact Us</h3>
                                <p>For any questions or concerns regarding your privacy, you may contact us using the following details:</p><br/>
                                <h3>Alarm Tech Tester Support: https://alarmtechtester.com/contact</h3>
                            </TabPanel>
                            <TabPanel>
                                <h1 className="text-2xl mb-4 font-bold">Terms of Service</h1>
                                <p>These Terms of Service govern your use of the website located at https://alarmtechtester.com and any related services provided by Alarm Tech Tester.</p><br/>
                                <p>By accessing https://alarmtechtester.com, you agree to abide by these Terms of Service and to comply with all applicable laws and regulations. If you do not agree with these Terms of Service, you are prohibited from using or accessing this website or using any other services provided by Alarm Tech Tester.</p><br/>
                                <p>We, Alarm Tech Tester, reserve the right to review and amend any of these Terms of Service at our sole discretion. Upon doing so, we will update this page. Any changes to these Terms of Service will take effect immediately from the date of publication.</p><br/>
                                <p className="font-bold">These Terms of Service were last updated on 18 March 2022.</p><br/>
                                <h3 className="font-bold">Limitations of Use</h3>
                                <p>By using this website, you warrant on behalf of yourself, your users, and other parties you represent that you will not:</p><br/>
                                <p>modify, copy, prepare derivative works of, decompile, or reverse engineer any materials and software contained on this website; remove any copyright or other proprietary notations from any materials and software on this website; transfer the materials to another person or “mirror” the materials on any other server; knowingly or negligently use this website or any of its associated services in a way that abuses or disrupts our networks or any other service Alarm Tech Tester provides; use this website or its associated services to transmit or publish any harassing, indecent, obscene, fraudulent, or unlawful material; use this website or its associated services in violation of any applicable laws or regulations; use this website in conjunction with sending unauthorized advertising or spam; harvest, collect, or gather user data without the user’s consent; or use this website or its associated services in such a way that may infringe the privacy, intellectual property rights, or other rights of third parties.</p><br/>
                                <h3 className="font-bold">Intellectual Property</h3>
                                <p>The intellectual property in the materials contained in this website are owned by or licensed to Alarm Tech Tester and are protected by applicable copyright and trademark law. We grant our users permission to download one copy of the materials for personal, non-commercial transitory use.</p><br/>
                                <p>This constitutes the grant of a license, not a transfer of title. This license shall automatically terminate if you violate any of these restrictions or the Terms of Service, and may be terminated by Alarm Tech Tester at any time.</p><br/>
                                <h3 className="font-bold">User-Generated Content</h3>
                                <p>You retain your intellectual property ownership rights over content you submit to us for publication on our website. We will never claim ownership of your content, but we do require a license from you in order to use it.</p><br/>
                                <p>When you use our website or its associated services to post, upload, share, or otherwise transmit content covered by intellectual property rights, you grant to us a non-exclusive, royalty-free, transferable, sub-licensable, worldwide license to use, distribute, modify, run, copy, publicly display, translate, or otherwise create derivative works of your content in a manner that is consistent with your privacy preferences and our Privacy Policy.</p><br/>
                                <p>The license you grant us can be terminated at any time by deleting your content. However, to the extent that we (or our partners) have used your content in connection with commercial or sponsored content, the license will continue until the relevant commercial or post has been discontinued by us.</p><br/>
                                <h3 className="font-bold">Liability</h3>
                                <p>Our website and the materials on our website are provided on an 'as is' basis. To the extent permitted by law, Alarm Tech Tester makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property, or other violation of rights.</p><br/>
                                <p>In no event shall Alarm Tech Tester or its suppliers be liable for any consequential loss suffered or incurred by you or any third party arising from the use or inability to use this website or the materials on this website, even if Alarm Tech Tester or an authorized representative has been notified, orally or in writing, of the possibility of such damage.</p><br/>
                                <p>In the context of this agreement, “consequential loss” includes any consequential loss, indirect loss, real or anticipated loss of profit, loss of benefit, loss of revenue, loss of business, loss of goodwill, loss of opportunity, loss of savings, loss of reputation, loss of use and/or loss or corruption of data, whether under statute, contract, equity, tort (including negligence), indemnity, or otherwise.</p><br/>
                                <p>Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p><br/>
                                <h3 className="font-bold">Accuracy of Materials</h3>
                                <p>The materials appearing on our website are not comprehensive and are for general information purposes only. Alarm Tech Tester does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on this website, or otherwise relating to such materials or on any resources linked to this website.</p><br/>
                                <h3 className="font-bold">Links</h3>
                                <p>Alarm Tech Tester has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement, approval, or control by Alarm Tech Tester of the site. Use of any such linked site is at your own risk and we strongly advise you make your own investigations with respect to the suitability of those sites.</p><br/>
                                <h3 className="font-bold">Right to Terminate</h3>
                                <p>We may suspend or terminate your right to use our website and terminate these Terms of Service immediately upon written notice to you for any breach of these Terms of Service.</p><br/>
                                <h3 className="font-bold">Severance</h3>
                                <p>Any term of these Terms of Service which is wholly or partially void or unenforceable is severed to the extent that it is void or unenforceable. The validity of the remainder of these Terms of Service is not affected.</p><br/>
                                <h3 className="font-bold">Governing Law</h3>
                                <p>These Terms of Service are governed by and construed in accordance with the laws of USA. You irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p><br/>
                            </TabPanel>
                            <TabPanel className={"mt-8"}>
                                <h2 className="text-2xl mb-4 font-bold">Cookie Policy</h2>
                                <p>We use cookies to help improve your experience of our website at https://alarmtechtester.com. This cookie policy is part of Alarm Tech Tester's privacy policy. It covers the use of cookies between your device and our site.</p><br/>
                                <p>We also provide basic information on third-party services we may use, who may also use cookies as part of their service. This policy does not cover their cookies.</p><br/>
                                <p>If you don’t wish to accept cookies from us, you should instruct your browser to refuse cookies from https://alarmtechtester.com. In such a case, we may be unable to provide you with some of your desired content and services.</p><br/>
                                <h3 className="font-bold">What is a Cookie?</h3>
                                <p>A cookie is a small piece of data that a website stores on your device when you visit. It typically contains information about the website itself, a unique identifier that allows the site to recognize your web browser when you return, additional data that serves the cookie’s purpose, and the lifespan of the cookie itself.</p><br/>
                                <p>Cookies are used to enable certain features (e.g. logging in), track site usage (e.g. analytics), store your user settings (e.g. time zone, notification preferences), and to personalize your content (e.g. advertising, language).</p><br/>
                                <p>Cookies set by the website you are visiting are usually referred to as first-party cookies. They typically only track your activity on that particular site. Cookies set by other sites and companies (i.e. third parties) are called third-party cookies They can be used to track you on other websites that use the same third-party service.</p><br/>
                                <h3 className="font-bold">Types of Cookies and How We Use Them:</h3><br/>
                                <h4 className="font-bold">Essential Cookies</h4>
                                <p>Essential cookies are crucial to your experience of a website, enabling core features like user logins, account management, shopping carts, and payment processing.</p><br/>
                                <p>We use essential cookies to enable certain functions on our website.</p><br/>
                                <h4 className="font-bold">Performance Cookies</h4>
                                <p>Performance cookies track how you use a website during your visit. Typically, this information is anonymous and aggregated, with information tracked across all site users. They help companies understand visitor usage patterns, identify and diagnose problems or errors their users may encounter, and make better strategic decisions in improving their audience’s overall website experience. These cookies may be set by the website you’re visiting (first-party) or by third-party services. They do not collect personal information about you.</p><br/>
                                <p>We use performance cookies on our site.</p><br/>
                                <h4 className="font-bold">Functionality Cookies</h4>
                                <p>Functionality cookies are used to collect information about your device and any settings you may configure on the website you’re visiting (like language and time zone settings). With this information, websites can provide you with customized, enhanced, or optimized content and services. These cookies may be set by the website you’re visiting (first-party) or by third-party services.</p><br/>
                                <p>We do not use this type of cookie on our site.</p><br/>
                                <h4 className="font-bold">Targeting/Advertising Cookies</h4>
                                <p>Targeting/advertising cookies help determine what promotional content is most relevant and appropriate to you and your interests. Websites may use them to deliver targeted advertising or limit the number of times you see an advertisement. This helps companies improve the effectiveness of their campaigns and the quality of content presented to you. These cookies may be set by the website you’re visiting (first-party) or by third-party services. Targeting/advertising cookies set by third-parties may be used to track you on other websites that use the same third-party service.</p><br/>
                                <p>We do not use this type of cookie on our site.</p><br/>
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
